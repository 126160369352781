import React, { useEffect, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import MenuPrimary from './mobile/MenuPrimary';
import Search from './search';
import QuickLinks from './QuickLinks';
import MenuContent from './desktop/MenuContent';
import Logo from './Logo';
import TopNavigationReducer from './reducers/TopNavigationReducer';
import { thunk } from 'redux-thunk';
import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { setTotalCartQuantity } from './actions/TopNavigationActions';

function MainNavigation({ TopMenu }) {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [activeMenuContent, setActiveMenuContent] = useState();
  const [cartQuantity, setCartQuantity] = useState();
  const [animationClasses, setAnimationClasses] = useState('');
  const showSearchRef = useRef(showSearch);

  let lastScrollY = 0;
  let desktopMenuWrapper = null;
  let mobileMenuWrapper = null;

  useEffect(() => {
    showSearchRef.current = showSearch;
  }, [showSearch]);

  const onMenuMouseLeave = (e) => {
    if (
      !e.relatedTarget?.classList ||
      (!e.relatedTarget?.closest('.menuDesktop') && !e.relatedTarget?.closest('.menuContent'))
    ) {
      setShowMenu(false);
      setActiveMenuContent(null);
    }
  };
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('btn--toggleMenu')) return;
    const isClickOnSearchOverlay = e.target.closest('.searchOverlay');
    if (isClickOnSearchOverlay) {
      setShowSearch(false);
    }
    if (!e.target.closest('.mainNavigation') && !e.target.closest('.search') && !e.target.closest('.menuContent')) {
      setShowMenu(false);
      setActiveMenuContent(null);
    }
  };

  useEffect(() => {
    setSlidingMenu();
    setTransparency();
  }, []);

  const setTransparency = () => {
    const rootElement = document.getElementById('REACT_mainnavigation');
    const hero = document.querySelector('.hero-component');
    if (hero) {
      rootElement.classList.add('transparent');
      rootElement.classList.add('position-absolute');
      document.addEventListener('scroll', (e) => {
        if (window.scrollY < hero.clientHeight * 0.25) {
          rootElement.classList.add('transparent');
        } else {
          rootElement.classList.remove('transparent');
        }
      });
    }
  };

  const setSlidingMenu = () => {
    lastScrollY = 0;
    desktopMenuWrapper = document.querySelector('.menu-wrapper.desktop');
    mobileMenuWrapper = document.querySelector('.menu-wrapper.mobile');

    if (!desktopMenuWrapper || !mobileMenuWrapper) return;

    document.addEventListener('scroll', (e) => {
      const menuWrapper = window.innerWidth < 992 ? mobileMenuWrapper : desktopMenuWrapper;
      const scrollDirectionUp = window.scrollY < lastScrollY;
      let contentHeightAboveMenu = document.querySelector('.hellobar-component ')?.clientHeight;
      if (!contentHeightAboveMenu) contentHeightAboveMenu = 0;

      if (scrollDirectionUp && window.scrollY > contentHeightAboveMenu + menuWrapper.clientHeight) {
        setAnimationClasses('expanded');
      } else if (!scrollDirectionUp || window.scrollY <= contentHeightAboveMenu) {
        if (window.scrollY <= contentHeightAboveMenu) {
          setAnimationClasses('');
        } else if (menuWrapper.classList.contains('expanded') && !showSearchRef.current) {
          setAnimationClasses('collapsed');
        }
      }
      if (window.scrollY >= contentHeightAboveMenu + menuWrapper.clientHeight) {
        menuWrapper.classList.add('idle');
      } else {
        menuWrapper.classList.remove('idle');
      }

      lastScrollY = window.scrollY;
    });
  };

  useEffect(() => {
    const scrollBarHideClass = 'overflow-hidden';
    if (showSearch && activeMenuContent) setActiveMenuContent(null);
    if (!showMenu && !showSearch) {
      document.documentElement.classList.remove(scrollBarHideClass);
    } else {
      document.documentElement.classList.add(scrollBarHideClass);
    }
  }, [showMenu, showSearch]);

  useEffect(() => {
    const rootElement = document.getElementById('REACT_mainnavigation');
    if (activeMenuContent || showMenu || showSearch) rootElement.classList.remove('transparent');
  }, [activeMenuContent, showMenu, showSearch]);

  useEffect(() => {
    minicart.loadQuantity();
    document.addEventListener('mouseup', handleClickOutside);
    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, []);

  const trackNavigation = (link, headerSection, labelName, categoryLabel, isLinkWithImage) => {
    var values = {
      event: 'navigationMain',
      headerSection: headerSection,
      parentLabel: categoryLabel,
      labelName: labelName,
      clickedurl: window.location.host + link,
      navigationType: isLinkWithImage ? 'linkwithimage' : 'default',
    };
    google.tagmanager.add(values);
  };

  var minicart = (function () {
    var self = {
      loadQuantity: function () {
        if (!_THULEDATA.isB2CEnabled && !_THULEDATA.isExtranet) return;

        let cartQtyApiUrl = _THULEDATA._MainNavigation.CartQuantityApiUrl;
        if (_THULEDATA.isExtranet) {
          cartQtyApiUrl += localStorage.getItem('cartIdentifier');
        }
        fetch(cartQtyApiUrl)
          .then((response) => response.json())
          .then((data) => {
            setCartQuantity(data && data > 0 ? data : null);
            if (_THULEDATA.isExtranet) store.dispatch(setTotalCartQuantity(data));
          });
      },
    };
    return self;
  })();

  const trackSecondaryNavigation = (label, url) => {
    var values = {
      event: 'navigationSecondary',
      labelName: label,
    };
    if (url) values.clickedUrl = window.location.host + url;
    google.tagmanager.add(values);
  };
  const removeTransparency = () => {
    const rootElement = document.getElementById('REACT_mainnavigation');
    rootElement.classList.remove('transparent');
  };
  return (
    <>
      <div
        className={`menu-wrapper mobile d-lg-none thule-container layout__content p-0 ${animationClasses} ${showMenu ? 'menu--open' : ''
          }`}
      >
        <div className="menu__content px-6">
          <Logo trackSecondaryNavigation={trackSecondaryNavigation} />
          <QuickLinks
            setShowMenu={setShowMenu}
            showMenu={showMenu}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            renderAsMobile={true}
            cartQuantity={cartQuantity}
            trackSecondaryNavigation={trackSecondaryNavigation}
          />
        </div>
        {showSearch && <Search setShowSearch={setShowSearch} />}
        {showMenu && (
          <MenuPrimary
            trackNavigation={trackNavigation}
            trackSecondaryNavigation={trackSecondaryNavigation}
            showSearch={showSearch}
            TopMenu={TopMenu}
            {...window._THULEDATA._MainNavigation}
          />
        )}
      </div>

      <div
        onMouseEnter={removeTransparency}
        className={`menu-wrapper desktop d-none d-lg-block ${window._THULEDATA.isCheckout ? 'is-checkout' : ''} ${animationClasses} ${activeMenuContent ? 'menu--open' : ''
          }`}
      >
        <div className="menuPrimary layout__content thule-container ">
          <Logo trackSecondaryNavigation={trackSecondaryNavigation} />
          <nav className="mainNavigation" aria-label="Main navigation">
            <div className="d-flex w-100 h-100 justify-content-center">
              <ul className="menuDesktop p-0 mb-0" onMouseLeave={(e) => onMenuMouseLeave(e)}>
                {TopMenu.map((menuItem, keyIndex) => {
                  return (
                    <li className={`menuDesktop__menuItem ${menuItem.Link ? 'link' : ''}`} key={keyIndex}>
                      {menuItem.Link ? (
                        <a
                          onMouseEnter={() => {
                            setShowMenu(false);
                            setActiveMenuContent(null);
                          }}
                          href={menuItem.Link}
                          id={menuItem.Title}
                          className={`menuItem__link px-5 px-lg-3 px-xl-4 py-0`}
                        >
                          <span className="menuItem__title heading6">{menuItem.Title}</span>
                        </a>
                      ) : (
                        <button
                          id={menuItem.Title}
                          className={`btn menuItem__btn px-5 px-lg-3 px-xl-4 py-0 ${activeMenuContent && activeMenuContent == menuItem ? 'active' : ''
                            }`}
                          onMouseEnter={() => setActiveMenuContent(menuItem)}
                        >
                          <span className="menuItem__title heading6">{menuItem.Title}</span>
                        </button>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <QuickLinks
              setShowMenu={setShowMenu}
              showMenu={showMenu}
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              renderAsMobile={false}
              cartQuantity={cartQuantity}
              trackSecondaryNavigation={trackSecondaryNavigation}
            />
          </nav>
        </div>

        {activeMenuContent && (
          <MenuContent
            onMenuMouseLeave={onMenuMouseLeave}
            trackNavigation={trackNavigation}
            menuItem={activeMenuContent}
          />
        )}
      </div>
    </>
  );
}

const rootReducer = combineReducers({
  TopNavigationReducer,
});

let preloadedData = {};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(rootReducer, preloadedData, enhancer);

const rootElement = document.getElementById('REACT_mainnavigation');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <MainNavigation {...window._THULEDATA._MainNavigation} />
    </Provider>
  );
}
